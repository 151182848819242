import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M32.78,114.672c0,0.28-0.14,0.448-0.504,0.448l-2.492,0c-0.588,0-0.784-0.224-0.952-0.672l-1.456-4.116l-7.084,0l-1.456,4.2c-0.112,0.364-0.308,0.588-0.756,0.588l-2.492,0c-0.28,0-0.392-0.14-0.392-0.364c0-0.084,0-0.196,0.056-0.308l6.832-18.144c0.14-0.336,0.336-0.56,0.756-0.56l2.24,0c0.42,0,0.672,0.196,0.784,0.504l6.86,18.06c0.056,0.14,0.056,0.252,0.056,0.364z M26.424,107.616l-1.988-5.908c-0.252-0.784-0.42-1.372-0.56-1.82l-0.056,0c-0.14,0.476-0.308,1.064-0.588,1.848l-1.988,5.88l5.18,0z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
